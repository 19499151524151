// ******************************************************* Bulma ****************************************************
@charset "utf-8";
$fa-font-path: "./../../node_modules/@fortawesome/fontawesome-free/webfonts";

@import "./../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "./../../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "./../../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "./../../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "../../node_modules/bulma/sass/utilities/initial-variables";
@import "../../node_modules/bulma/sass/utilities/functions";
@import "../../node_modules/bulma/sass/utilities/derived-variables";
@import "../../node_modules/bulma/bulma";

$green: #66c88e;
$success: $green;
$primary: #c066c8;

// tabs
$tabs-toggle-link-active-background-color: #66c88e;
$tabs-toggle-link-active-border-color: darkgreen;
$tabs-link-color: black;

@import "../../node_modules/bulma-pageloader/dist/css/bulma-pageloader.sass";
@import "../../node_modules/bulma-switch/dist/css/bulma-switch.sass";
@import "../../node_modules/bulma-slider/dist/css/bulma-slider.sass";
@import "../../node_modules/bulma-checkradio/dist/css/bulma-checkradio.sass";

// $body-family: "Open Sans";
.has-text-success {
  color: $green;
}

// ******************************************************* LAYOUT ****************************************************

// Small tablets and large smartphones (landscape view)
$screen-mobile-s-min: 320px;

$screen-mobile-m-min: 375px;

$screen-mobile-l-min: 425px;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-tablet-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Large tablets and desktops
$screen-mac-min: 1440px;

// full hd
$screen-fhd-min: 1900px;

@mixin ms {
  @media (min-width: #{$screen-mobile-s-min}) {
    @content;
  }
}

@mixin mm {
  @media (min-width: #{$screen-mobile-m-min}) {
    @content;
  }
}
@mixin ml {
  @media (min-width: #{$screen-mobile-l-min}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-tablet-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Extra large devices
@mixin fhd {
  @media (min-width: #{$screen-fhd-min}) {
    @content;
  }
}

// Extra large devices
@mixin mac {
  @media (min-width: #{$screen-mac-min}) {
    @content;
  }
}

html {
  height: 100%;
}
// Needed otherwise iframes show a disabled scrollbar
body {
  overflow-y: auto;
  overflow-x: auto;
  font-family: "Noto Sans", "Open Sans", sans-serif;
  // font-weight: 100;
  font-size: small;
  margin: 0;
  padding: 0;
  //background-color: #fff;
  height: 100%;
  background-color: white;
}

.animated-background {
  background: linear-gradient(-45deg, rgb(80, 80, 80), rgb(0, 0, 0));
  //	background-size: 200% 200%;
  //	animation: Gradient 15s ease infinite;
  height: 100%;
  .navbar-link {
    color: $green;
  }
}
.toast {
  font-family: "Open Sans", sans-serif;
}

#toast-container {
  .toast-error {
    background-color: firebrick;
    opacity: 1;
  }
}
.iotpool {
  color: $green;
}

.compass {
  width: 260px;
  height: 260px;
  position: relative;
  margin: 150px auto;
  margin-bottom: 50px;
  background: #444;
  border-radius: 50%;
}

.compass-main {
  width: 94%;
  height: 94%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -47%;
  margin-left: -47%;
  background: radial-gradient(#eee 50%, #ddd 80%);
  border-radius: 50%;
}

.bt-center {
  width: 16px;
  height: 16px;
  position: absolute;
  z-index: 100000;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
  display: block;
  background: #444;
  border-radius: 50%;
}

.needle {
  width: 12px;
  height: 12px;
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -6px;
}

.needle-inside {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: -138px;
  right: -54px;
  border: 80px solid;
  border-left: 6px solid;
  border-right: 6px solid;
  border-color: #b20000 transparent transparent transparent;
  z-index: -10000;
  //transform : rotate(220deg);
}

.north-label {
  position: absolute;
  top: 10px;
  left: 116px;
}

.east-label {
  position: absolute;
  top: 114px;
  right: 12px;
}

.west-label {
  position: absolute;
  top: 114px;
  left: 12px;
}

.south-label {
  position: absolute;
  bottom: 6px;
  left: 116px;
}
